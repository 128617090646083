import { AfterViewInit, Component } from '@angular/core';
import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import { GOOGLE_MAPS_API_KEY } from './config';
import { ProjectStateStoreService } from './services/projectStateStore/project-state-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'urbanDashboard';
  isLoaderRemoved : boolean = false;

  constructor(private router: Router, private projectStateStoreService: ProjectStateStoreService) {
    LicenseManager.setLicenseKey('CompanyName=Urban dashboard ltd,LicensedApplication=Urban-dashboard,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021861,ExpiryDate=3_November_2022_[v2]_MTY2NzQzMzYwMDAwMA==991cf1b11b490b071270c6be34a25777');
    router.events.pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.isLoaderRemoved = false;
      });
    this.projectStateStoreService.loadPermissionsFromStorage();
  }

  started() {
    this.isLoaderRemoved = false;
  }

  completed() {
    this.isLoaderRemoved = true;
  }

  ngAfterViewInit(): void {
    let language = sessionStorage.getItem('lang');
    language = !!language ? language : 'he';
    if (language === 'he') {
      language = 'iw';
    }
    const googleScript = document.createElement('script');
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&language=${language}&libraries=places,geometry`;
    googleScript.type = 'text/javascript';
    document.body.appendChild(googleScript);
    document.body.removeChild(googleScript);
  }
}
