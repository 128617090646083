import { Injectable } from '@angular/core';
import { Feature, Geometry} from 'geojson';
import { BehaviorSubject, Observable, of, pipe, throwError } from 'rxjs';
import { concatMap, map, switchMap, take, tap, catchError, filter, shareReplay } from 'rxjs/operators';
import { SCOPELINE_INDEX_OLD } from 'src/app/config';
import { EntityMap } from 'src/app/models/entity-map';
import { EntityProperties } from 'src/app/models/entity-properties';
import Utils from 'src/app/utils/utils';
import { CrudService } from '../crud/crud.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectStoreService {

  private projectEntitiesMapSubject = new BehaviorSubject<EntityMap>({});
  projectEntitiesMap$: Observable<EntityMap> = this.projectEntitiesMapSubject.asObservable();


  get projectEntitiesArray$(): Observable<Feature<Geometry, EntityProperties>[]> {
    return this.projectEntitiesMap$.pipe(
      map(entityMap => Object.values(entityMap))
    );
  }

  get projectName$(): Observable<string> {
    return this.findEntityById(SCOPELINE_INDEX_OLD)
      .pipe(
        map(entity => entity.properties.projectName),
        take(1)
      )
  }

  constructor(private crudService: CrudService) {}

  nextProjectEntities(entityMap: EntityMap) {
    this.projectEntitiesMapSubject.next(entityMap);
  }

  projectEntitiesMapSubjectValue() {
    return this.projectEntitiesMapSubject.getValue();
  }

  findEntityById(id: string | number): Observable<Feature<Geometry, EntityProperties>> {
    return this.projectEntitiesMap$.pipe(
      map(entityMap => entityMap[id]),
      take(1)
    );
  }

  scopeLineEntity$(): Observable<Feature<Geometry, EntityProperties>> {
    return this.projectEntitiesMap$.pipe(
      map(entityMap => entityMap[SCOPELINE_INDEX_OLD])
    )
  } 

  getEntitiesByIds(ids: (string | number)[]): Observable<Feature<Geometry, EntityProperties>[]> {
    return this.projectEntitiesMap$.pipe(
      map(entityMap => ids.map(id => entityMap[id]).filter(entity => !!entity))
    );
  }


  // with saving
  // addNewEntity(entity: Feature<Geometry, EntityProperties>): Observable<any> {
  //   const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
  //   const newEntityMap: EntityMap = {...entityMap, [entity.id]: entity};
  //   console.log("New EntityMap Next: ", newEntityMap)
  //   // Update local map optimistically
  //   this.projectEntitiesMapSubject.next(newEntityMap);
  //   console.log("After Next: ", Utils.clone(this.projectEntitiesMapSubject.getValue()))
  
  //   // Update backend and handle errors
  //   return this.crudService.upsertEntity(entity).pipe(
  //     tap(data => {console.log("AddNewEntityData", data)}),
  //     catchError(err => {
  //       // Revert local map if API call fails
  //       this.projectEntitiesMapSubject.next(entityMap);
  //       return throwError(err);
  //     })
  //   );
  // }

  // without saving 
  addNewEntity(entity: Feature<Geometry, EntityProperties>) {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    const newEntityMap: EntityMap = {...entityMap, [entity.id]: entity};
    // Update local map optimistically
    this.projectEntitiesMapSubject.next(newEntityMap);
  }

  addEntities(entities: Feature<Geometry, EntityProperties>[]): Observable<any> {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    // Add the new entities to the map
    entities.forEach(entity => {
      entityMap[entity.id] = entity;
    });

    this.projectEntitiesMapSubject.next(entityMap);
  
    // Call the saveEntitiesConfigsBulkObservable method of the CrudService
    return this.crudService.saveEntitiesConfigsBulkObservable(entities).pipe(
      catchError(err => {
        // If the update fails, revert the change in the local map
        entities.forEach(entity => {
          delete entityMap[entity.id];
        });
        this.projectEntitiesMapSubject.next(entityMap);
        return throwError(err);
      })
    );
  }

  updateEntity(entity: Feature<Geometry, EntityProperties>): void {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    entityMap[entity.id] = entity;
    this.projectEntitiesMapSubject.next(entityMap);
  }
  

  upsertEntity(entity: Feature<Geometry, EntityProperties>){
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    const entityMapCloneForError = Utils.clone(entityMap);
    entityMap[entity.id] = entity;
    this.nextProjectEntities(entityMap);
    return this.crudService.upsertEntity(entity).pipe(
      take(1),
      catchError(err => {
        // Revert the update in case of error
        this.projectEntitiesMapSubject.next(entityMapCloneForError);
        return throwError(err);
      })
    );
  }


  upsertEntities(entities: Feature<Geometry, EntityProperties>[]): Observable<any> {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    const entityMapCloneForError = Utils.clone(entityMap);
    // update or add each entity to the entity map
    entities.forEach((entity) => {
      const entityId = entity.id.toString();
      entityMap[entityId] = entity;
    });
  
    this.projectEntitiesMapSubject.next(entityMap);
  
    return this.crudService.saveEntitiesConfigsBulkObservable(entities).pipe(
      catchError(err => {
        // Revert the update in case of error
        this.projectEntitiesMapSubject.next(entityMapCloneForError);
        return throwError(err);
      })
    );
  }
  

  deleteEntity(entity: Feature<Geometry, EntityProperties>): Observable<any> {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    const entityId = entity.id.toString();
    let projectName = entity.properties.projectName;
    if (!projectName) {
      const scopeLineEntity = entityMap[SCOPELINE_INDEX_OLD];
      projectName = scopeLineEntity.properties.projectName
    }
    delete entityMap[entityId];
    this.projectEntitiesMapSubject.next(entityMap);

    const entitiesId = [entityId];

    return this.crudService.deleteEntitiesConfigsBulkObservable(projectName, entitiesId).pipe(
      catchError(err => {
        // Revert the update in case of error
        entityMap[entity.id] = entity;
        this.projectEntitiesMapSubject.next(entityMap);
        return throwError(err);
      })
    );
  }

  // deleteEntities(entities: Feature<Geometry, EntityProperties>[]): Observable<any> {
  //   if (entities.length === 0) {
  //     return of(null);
  //   }
  //   const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
  //   const scopeLineEntity = entityMap[SCOPELINE_INDEX_OLD];
  //   const projectName = scopeLineEntity.properties.projectName;
  //   const entitiesId = entities.map((entity) => {
  //     const entityId = entity.id.toString();
  //     delete entityMap[entityId];
  //     return entityId;
  //   });
  //   this.projectEntitiesMapSubject.next(entityMap);

  //   return this.crudService.deleteEntitiesConfigsBulkObservable(projectName, entitiesId).pipe(
  //     catchError(err => {
  //       // Revert the update in case of error
  //       entities.forEach((entity) => {
  //           entityMap[entity.id.toString()] = entity;
  //       });
  //       this.projectEntitiesMapSubject.next(entityMap);
  //       return throwError(err);
  //     })
  //   );
  // }

  // removeEntitiesFromProject(entities: Feature<Geometry, EntityProperties>[]){
  //   // if (entities.length === 0) {
  //   //   return of(null);
  //   // }
  //   const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());

  //   entities.map((entity) => {
  //     delete entityMap[entity.id];
  //   });
  //   this.projectEntitiesMapSubject.next(entityMap);
  // }

  deleteEntities(entities: Feature<Geometry, EntityProperties>[]): Observable<any> {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    const entityMapCloneForError = Utils.clone(entityMap);
    const entitiesId: string[] = [];
  
    // Remove the entities from the entityMap
    entities.forEach(entity => {
      const entityId = entity.id.toString();
      entitiesId.push(entityId);
      delete entityMap[entityId];
    });
  
    this.projectEntitiesMapSubject.next(entityMap);
  
    let projectName: string;
    if (entities.length > 0) {
      projectName = entities[0].properties.projectName;
      if (!projectName) {
        const scopeLineEntity = entityMap[SCOPELINE_INDEX_OLD];
        projectName = scopeLineEntity.properties.projectName;
      }
    }
  
    return this.crudService.deleteEntitiesConfigsBulkObservable(projectName, entitiesId).pipe(
      shareReplay(),
      catchError(err => {
        // Revert the update in case of error
        entities.forEach(entity => {
          entityMap[entity.id] = entity;
        });
        this.projectEntitiesMapSubject.next(entityMapCloneForError);
        return throwError(err);
      })
    );
  }

  deleteEntitiesWithoutBackendUpdate(entities: Feature<Geometry, EntityProperties>[]): Observable<any> {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
  
    // Remove the entities from the entityMap
    entities.forEach(entity => {
      const entityId = entity.id.toString();
      delete entityMap[entityId];
    });
  
    this.projectEntitiesMapSubject.next(entityMap);
  
    return of(null);
  }

  removeEntities(entities: Feature<Geometry, EntityProperties>[]): void {
    const entityMap: EntityMap = Utils.clone(this.projectEntitiesMapSubject.getValue());
    entities.forEach(entity => {
      delete entityMap[entity.id];
    });
    this.projectEntitiesMapSubject.next(entityMap);
  }
  
  

}
