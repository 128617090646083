import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { MainPageService } from 'src/app/components/main-page/main-page.service';
import { ProjectEditService } from 'src/app/components/project-edit/project-edit.service';
import { GoogleGeocoderService } from 'src/app/services/geocoding/google-geocoder.service';
import { GovmapService } from 'src/app/services/govMap/gov-map.service';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';
import { WindowManagerService, WindowType } from 'src/app/services/window-manager.service';
import { LayerConfig } from '../../../components/main-page/panels/panel-layers/tabs/section-map-layer/layer-config';
import { ExternalLayer } from 'src/app/models/external-layer';
import { TranslateService } from '@ngx-translate/core';

declare const google: any;


@Component({
  selector: 'app-location-searchbar',
  templateUrl: './location-searchbar.component.html',
  styleUrls: ['./location-searchbar.component.scss']
})

export class LocationSearchbarComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject();

  @Output() locationSelected = new EventEmitter<string>(); 

  @Input() isEdit: boolean = false;

  dynamicWidth: string;
  
  addressesOptions;
  addressesFeatures;
  inputAddress;
  addressFromParcel;

  selectedSearchType: string = '0';

  inputParcelControl = new FormControl('');
  inputBlockControl = new FormControl('');

  constructor(
    private translate: TranslateService,
    private googleGeocoderService: GoogleGeocoderService,
    private projectEditService: ProjectEditService,
    public windowService: WindowManagerService,
    private projectStateStoreService: ProjectStateStoreService,
    private mainPageService: MainPageService, 
    private govmapService: GovmapService
    ) {
      this.inputParcelControl.setValidators(
        (control) => control.value && control.value.length > 0 ? null : { required: true }
      );
      this.inputBlockControl.setValidators(
        (control) => control.value && control.value.length > 0 ? null : { required: true }
      );

      this.updateWidth();
      this.translate.onLangChange.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.updateWidth();
    });
  }

  googleService;
  googleGeocoder;

  get isPanelLayersOpen(){
    return this.windowService.windowList.panelLayers;
  }

  updateWidth(): void {
    const currentLang = this.translate.currentLang;
    this.dynamicWidth = this.getWidthByLanguage(currentLang);
  }

  getWidthByLanguage(language: string): string {
    switch (language) {
      case 'en':
        return '250px';
      case 'he':
        return '285px';
      default:
        return '250px';
    }
  }



  ngOnInit(): void {

    combineLatest([
      this.inputParcelControl.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ),
      this.inputBlockControl.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
    ]).subscribe(([parcel, block]) => {
      if (parcel && block) {
        this.searchByParcel();
      }
    });

    this.googleService = new google.maps.places.AutocompleteService();
    this.googleGeocoder = new google.maps.Geocoder();

  }

  ngOnDestroy() {
    // this.projectStateStoreService.hideScopeLine();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  //fly to element 
  searchAddress(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm && searchTerm.length > 0) {
      this.googleService.getQueryPredictions({input: searchTerm}, (predictions, status) => {
        if (predictions && status === 'OK') {
          this.addressesFeatures = predictions;
          this.addressesOptions = predictions.map(opt => opt.description);
        }
      })
    }
  }

  searchByParcel() {
    if (this.inputParcelControl.value && this.inputBlockControl.value) {
      this.addressFromParcel = '';
      const params = {  
        type: 1,  
        lot: this.inputBlockControl.value,  
        parcel: this.inputParcelControl.value  
      };
  
      this.govmapService.searchAndLocate(params).pipe(take(1))
        .subscribe((res) => {
         if(  res.data && res.data.length > 0) {
          this.addressFromParcel = res.data[0].Values.join(' ')
          const event = { target: { value: this.addressFromParcel } };
          this.searchAddress(event)
         }
        });
    }
  }

  flyTo() {
    if(!this.isEdit){
      if(this.selectedSearchType === '0' && this.addressesFeatures && this.inputAddress) {
        const flyToFeature = this.addressesFeatures.find(feat => feat.description === this.inputAddress);
        this.googleGeocoder.geocode({placeId: flyToFeature.place_id}).then((res) => {
          if(res) {
            const place = res.results[0];
            const centerLatLng = [
              place.geometry.location.lat(),
              place.geometry.location.lng()
            ];
            this.mainPageService.sendCenter(centerLatLng);
          }  
        });
      } else if(this.selectedSearchType === '1' && this.addressesFeatures) {
        const flyToFeature = this.addressesFeatures[0];
        this.googleGeocoder.geocode({placeId: flyToFeature.place_id}).then((res) => {
          if(res) {
            const place = res.results[0];
            const centerLatLng = [
              place.geometry.location.lat(),
              place.geometry.location.lng()
            ];
            this.mainPageService.sendCenter(centerLatLng);
            // LayerConfig.boundariesAndOwnershipPanelUrls['SECTION-MAP-LAYER.PARCELS'].isOn = true;
            //@ts-ignore
            const layer : ExternalLayer[] = [{
              ...LayerConfig.boundariesAndOwnershipPanelUrls['SECTION-MAP-LAYER.PARCELS'],

            }];
            this.projectStateStoreService.addExternalLayers(layer);
          }  
        });
      }
    } else{
      if(this.selectedSearchType === '0' && this.addressesFeatures && this.inputAddress) {
        this.locationSelected.emit(this.inputAddress);
        const flyToFeature = this.addressesFeatures.find(feat => feat.description === this.inputAddress);
        if(flyToFeature){
          this.googleGeocoderService.getLocationFromPlaceId(flyToFeature.place_id).pipe(takeUntil(this.componentDestroyed$))
          .subscribe(lngLat => {
            
            this.projectEditService.sendFeature(lngLat);
          });
        }
      } else if(this.selectedSearchType === '1' && this.addressesFeatures) {
        this.locationSelected.emit(this.addressesFeatures[0].description);
        const flyToFeature = this.addressesFeatures[0];
        if(flyToFeature){
        this.googleGeocoderService.getLocationFromPlaceId(flyToFeature.place_id).pipe(takeUntil(this.componentDestroyed$))
              .subscribe(lngLat => {
                this.projectEditService.sendFeature(lngLat);
          });
        }
      }
    }
  }


  onSearchTypeChange(value: string) {
    this.selectedSearchType = value;
  }

}
