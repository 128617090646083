import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ZorroAntdModule} from './zorro-antd.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoaderFullscreenComponent} from '../components/loader-fullscreen/loader-fullscreen.component';
import {IconsProviderModule} from '../icons/icons-provider.module';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {NumberInputSuffixDirective} from '../shared/directives/number-input-suffix/number-input-suffix.directive';
import { NumberInputPrefixDirective } from '../shared/directives/number-input-prefix/number-input-prefix.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {GltfViewerComponent} from "../shared/components/gltf-viewer/gltf-viewer.component";
import {DropDownMultipleComponent} from "../shared/components/drop-down-multiple/drop-down-multiple.component";
import { LoaderSmallComponent } from '../components/loader-small/loader-small.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EntityEditMenuComponent } from '../shared/components/entity-edit-menu/entity-edit-menu/entity-edit-menu.component';
import { LocationSearchbarComponent } from '../shared/components/location-searchbar/location-searchbar.component';
import { PopoverComponent } from '../shared/components/popover/popover.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    LoaderFullscreenComponent,
    NumberInputSuffixDirective,
    NumberInputPrefixDirective,
    GltfViewerComponent,
    DropDownMultipleComponent,
    LoaderSmallComponent,
    EntityEditMenuComponent,
    LocationSearchbarComponent,
    PopoverComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'he',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    IconsProviderModule,
    FormsModule,
    ReactiveFormsModule,
    ZorroAntdModule,
    NgxGoogleAnalyticsModule.forRoot('UA-179083680-1'),
    NgxGoogleAnalyticsRouterModule,
    DragDropModule
  ],
  exports: [
    LoaderFullscreenComponent,
    IconsProviderModule,
    FormsModule,
    ReactiveFormsModule,
    ZorroAntdModule,
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
    NumberInputSuffixDirective,
    NumberInputPrefixDirective,
    DragDropModule,
    GltfViewerComponent,
    DropDownMultipleComponent,
    LoaderSmallComponent,
    TranslateModule,
    EntityEditMenuComponent,
    LocationSearchbarComponent,
    PopoverComponent
  ]
})
export class SharedModule { }
