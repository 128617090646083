import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { filter, take, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var govmap: any;

@Injectable({
  providedIn: 'root',
})
export class GovmapService {
  private renderer: Renderer2;
  private mapInitialized$ = new BehaviorSubject<boolean>(false);
  private dummyDivId = 'govmap-dummy-div';

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.loadGovMap();
  }

  private loadGovMap(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://www.govmap.gov.il/govmap/api/govmap.api.js';
    this.renderer.appendChild(document.body, script);

    script.onload = () => {
      this.initMap();
    };
  }

  private initMap(): void {
    let dummyDiv = document.getElementById(this.dummyDivId);

    if (!dummyDiv) {
      dummyDiv = this.renderer.createElement('div');
      this.renderer.setAttribute(dummyDiv, 'id', this.dummyDivId);
      this.renderer.setStyle(dummyDiv, 'display', 'none');
      this.renderer.appendChild(document.body, dummyDiv);
    }

    // The token is for local use only
    govmap.createMap(this.dummyDivId, {
      token: environment.GOVMAP_TOKEN,
      layers: [],
      showXY: false,
      identifyOnClick: false,
      isEmbeddedToggle: false,
      background: '1',
      layersMode: 1,
      zoomButtons: false,
    });

    this.mapInitialized$.next(true);
    console.log('GovMap initialized');
  }

  public intersectFeatures(params: any): Observable<any> {
    return this.mapInitialized$.pipe(
      filter((initialized) => initialized === true),
      take(1),
      switchMap(() => {
        return new Observable<any>((observer) => {
          govmap.intersectFeatures(params).then(
            (response: any) => {
              observer.next(response);
              observer.complete();
            },
            (error: any) => {
              observer.error(error);
            }
          );
        });
      }),
      catchError((error) => {
        console.error('Error in intersectFeatures:', error);
        return throwError(() => error);
      })
    );
  }

  public searchAndLocate(params): Observable<any> {
    return this.mapInitialized$.pipe(
      filter((initialized) => initialized === true),
      take(1),
      switchMap(() => {
        return new Observable<any>((observer) => {
          govmap.searchAndLocate(params).then(
            (response) => {
              observer.next(response);
              observer.complete();
            },
            (error: any) => {
              observer.error(error);
            }
          );
        });
      }),
      catchError((error) => {
        console.error('Error in searchAndLocate:', error);
        return throwError(() => error);
      })
    );
  }

}


