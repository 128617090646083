import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleGeocoderService {

  googleService;
  googleGeocoder;

  constructor() {
    this.googleService = new google.maps.places.AutocompleteService();
    this.googleGeocoder = new google.maps.Geocoder();
  }

  searchAddress(searchTerm): Observable<any> {
    return from(new Promise((resolve, reject) => {
      this.googleService.getQueryPredictions({input: searchTerm}, (predictions, status) => {
        if (predictions && status === 'OK') {
          resolve(predictions);
        } else {
          reject(status);
        }
      });
    }));
  }

  getLocationFromPlaceId(placeId): Observable<any> {
    return from(new Promise((resolve, reject) => {
      this.googleGeocoder.geocode({placeId})
        .then((res) => {
          const place = res.results[0];
          const lngLat = [
            place.geometry.location.lng(),
            place.geometry.location.lat()
          ];
          resolve(lngLat);
        })
        .catch(error => reject(error));
    }));
  }
}
