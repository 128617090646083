import { is } from '@amcharts/amcharts4/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, of, pipe, Subscription } from 'rxjs';
import { tap, map, take, concatMap, retry, catchError } from 'rxjs/operators';
import { SCOPELINE_INDEX_OLD } from 'src/app/config';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CrudService } from 'src/app/services/crud/crud.service';
import { LoaderSmallService } from 'src/app/services/loaderSmall/loader-small.service';

@Injectable({
  providedIn: 'root'
})
export class WorkingPageService {

  scopeEntityToArchiveSub: Subscription;
  duplicateProjectSub: Subscription;

  private projectsPermittedSubject = new BehaviorSubject<{projectName: string, description: string, createdBy: string, date}[]>([]);
  projectsPermitted$: Observable<{projectName: string, description: string, createdBy: string, date}[]> = this.projectsPermittedSubject.asObservable()

  constructor(private crud: CrudService, private auth: AuthService, private loaderSmallService: LoaderSmallService) {
    this.loadAllPermittedProjects(false);
  }

  ngOnDestroy() {
    this.scopeEntityToArchiveSub.unsubscribe();
    this.duplicateProjectSub.unsubscribe();
  }

  private loadAllPermittedProjects(isArchive: boolean) {
    const loadProjects =  this.crud.getAllPermittedProjects(isArchive)
      .pipe(
        tap(projects => {
          this.projectsPermittedSubject.next(projects);
        })
      );
    this.loaderSmallService.showSmallLoaderUntilCompleted(loadProjects).subscribe();
  }

  archiveProject(projectName) {
    const allProjects =  this.projectsPermittedSubject.getValue();
    const newProjectsArray = allProjects.filter(project => project.projectName !== projectName)
    this.projectsPermittedSubject.next(newProjectsArray);
    let timestamp = new Date();
    let timestampString = timestamp.toISOString().slice(0, 19);
    const newArchivedName = `Archived-${projectName}-${timestampString}`
    const duplicateBeforeArchiving$ = this.duplicateProject(projectName, newArchivedName)
      .pipe(
        take(1),
      )

    const scopeEntityToArchive$ = this.crud.getEntity(newArchivedName, SCOPELINE_INDEX_OLD)
      .pipe(
        take(1),
        map( entity => {
          entity[0].properties.isArchive = true;
          return entity[0];
        })
      );

    let scopeEntityToArchive;

    const updateEntity$ = scopeEntityToArchive$
      .pipe(
        tap(entity => {scopeEntityToArchive = entity;}),
        concatMap(() => this.crud.upsertEntity(scopeEntityToArchive))
      );

    const deleteOldProject$ = this.deleteProject(projectName);

    const result$ = concat(duplicateBeforeArchiving$, updateEntity$, deleteOldProject$)
      .pipe(
        retry(2),
        catchError(error => {
          console.error(error);
          return of (error)
        })
      );

    this.scopeEntityToArchiveSub = result$.subscribe(
      // data => console.log(data),
      // error => console.error(error)
    );

  }

  duplicatePermittedProject(projectName) {
    const allProjects =  this.projectsPermittedSubject.getValue();
    const index = allProjects.findIndex(project => project.projectName == projectName);
    const duplicateName = this.generateCopyName(projectName, allProjects);
    const newCopiedProject: {projectName: string, description: string, createdBy: string, date} = {
      projectName: duplicateName,
      description: allProjects[index].description,
      createdBy: this.auth.getUsername(),
      date: new Date()
    }
    allProjects.splice(index + 1, 0, newCopiedProject)
    const newProjectsArray = [...allProjects];

    this.projectsPermittedSubject.next(newProjectsArray);

    const duplicateProject$ = this.duplicateProject(projectName, duplicateName);
    this.duplicateProjectSub = duplicateProject$.subscribe()
  }

  addNewProjectToArray(projectName, description, createdBy, date) {
    const allProjects =  this.projectsPermittedSubject.getValue();
    const newProjectToAdd: {projectName: string, description: string, createdBy: string, date} = {
      projectName,
      description,
      createdBy,
      date
    }
    const newAllProjectsArray = allProjects.slice();
    newAllProjectsArray.push(newProjectToAdd);
    this.projectsPermittedSubject.next(newAllProjectsArray);
  }

  replaceProjectWithEditedInArray(projectToReplaceName: string, editedProject: {projectName: string, description: string, createdBy: string, date}) {
    const allProjects = this.projectsPermittedSubject.getValue();
    const index = allProjects.findIndex(project => project.projectName === projectToReplaceName);
    const newProject = {...editedProject};
    const newAllProjectsArray = allProjects.slice(0);
    newAllProjectsArray[index] = newProject;
    this.projectsPermittedSubject.next(newAllProjectsArray)
  }

  deleteProject(project): Observable<any>{
    return this.crud.deleteProject(project)
      .pipe(
        take(1)
      )
  }

  duplicateProject(project, newName): Observable<any>{
    return this.crud.duplicateProject( newName, project)
      .pipe(
        take(1)
      )
  }

  generateCopyName(projectName: string, projects: { projectName: string, description: string }[]): string {
    let copyName = `copy-${projectName}`;
    let i = 1;
  
    while (projects.some(project => project.projectName === copyName)) {
      copyName = `copy${i}-${projectName}`;
      i++;
    }
  
    return copyName;
  }

}
