import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {ResizeEvent} from 'angular-resizable-element';

@Injectable({
  providedIn: 'root'
})
export class WindowManagerService {

  windowList: WindowList;
  isFullScreen:boolean = false;
  fullScreenActiveWindow?: WindowType;
  // When we go to full screen, we save the list of currently opened windows, before closing them all.
  // This way, we can re-open them when fullscreen ends.
  fullScreenCachedWindowList?: WindowList;
  panelTableComponents: {id: number; isPopup: boolean;}[] = [];
  windowsSizes$: Subject<WindowTypesSizes> = new Subject<WindowTypesSizes>();

  isCanAddPanelTableComponent() {
    return !this.panelTableComponents.find(table => !table.isPopup)
  }

  constructor() {
    this.windowList = {
      panelMapMain: true,
      panelMapMapbox: false,
      panelDashboard: false,
      panelLayers: true,
      panelTable: false,
      panelMainMenu: true,
      panelLayersLegends: false,
    }
  }

  openWindow(name:WindowType): void {
    if (name === WindowType.panelTable) {
      this.handlePanelTable();
    } else {
      this.windowList[name] = true;
    }
    this.triggerResizeEvent();
  }

  closeWindow(name:WindowType): void {
    this.windowList[name] = false;
    this.triggerResizeEvent();
  }

  toggleWindow(name:WindowType): void {
    if (name === WindowType.panelTable) {
     this.handlePanelTable();
    } else {
      this.windowList[name] = !this.windowList[name];
    }
    this.triggerResizeEvent();
  }

  handlePanelTable() {
    if (this.windowList[WindowType.panelTable]) {
      if (this.isCanAddPanelTableComponent()) {
        this.panelTableComponents.push({id: (new Date()).getTime(), isPopup: false});
      } else {
        if (this.panelTableComponents.length === 1) {
          this.windowList[WindowType.panelTable] = false;
        }
        const index = this.panelTableComponents.findIndex(panel => panel.isPopup);
        this.panelTableComponents.splice(index, 1);
      }
    } else {
      this.panelTableComponents.push({id: (new Date()).getTime(), isPopup: false});
      this.windowList[WindowType.panelTable] = true;
    }
  }

  closeTable(id: number) {
    const isHasNoPopupTable = !!this.panelTableComponents.find(table => !table.isPopup);
    this.panelTableComponents = this.panelTableComponents.filter(table => table.id !== id);
    if (!isHasNoPopupTable) {
      this.panelTableComponents.push({id: (new Date()).getTime(), isPopup: false});
    }
    if (this.panelTableComponents.length === 0) {
      this.windowList[WindowType.panelTable] = false;
    }
  }

  setWindowList(windows:WindowList):void {
    this.windowList = {...windows};
    this.triggerResizeEvent();
  }

  isWindowOpen(name:WindowType): boolean {
    return this.windowList[name];
  }

  enterFullScreen(name:WindowType):void{

    // if (this.isFullScreen()){
    //   this.exitFullScreen();
    // }
    // if (!this.isFullScreen()){
      // When we go to full screen, we save the list of currently opened windows, before closing them all.
      // This way, we can re-open them when fullscreen ends.
      this.fullScreenCachedWindowList = { ...this.windowList};
    // } else {
      // If there's already a fullscreen list, then we keep the old list, and simply disable the current window and enable the new one.
      // this.fullScreenCachedWindowList![this.fullScreenActiveWindow!] = false;
      // this.fullScreenCachedWindowList![name] = true;
      // this.closeWindow()
    // }

    //Close all windows except selected one.
    Object.entries(this.windowList).forEach(([key, value],index) => {
      const window:WindowType = key as keyof WindowList;
      if (window != name){
        this.closeWindow(window);
      }
    });

    // Mark the window as active.
    this.fullScreenActiveWindow = name;

    this.isFullScreen = true;
  }

  exitFullScreen():void{
    if (!this.fullScreenCachedWindowList){
      //If we don't have any cached windowList, then panic.
      console.error('Help! Trying to exit fullscreen, but we have no list of windows to restore.');
      return;
    }

    //Restore minimized windows. We go through each window, and if it's supposed to be open, we open it.
    Object.entries(this.fullScreenCachedWindowList).forEach(([key, value],index) => {
      const window:WindowType = key as keyof WindowList;

      //If window was open before we entered Fullscreen, we open it.
      if (value){
        this.openWindow(window);
      }

      //Close the currently fullscreen window, if it's not supposed to be open.
      if (window == this.fullScreenActiveWindow && value == false){
        this.closeWindow(this.fullScreenActiveWindow);
      }

      console.log(key, value,index);
    });

    //Remove old fullscreen data, mark isFullScreen false.
    this.fullScreenActiveWindow = undefined;
    this.fullScreenCachedWindowList = undefined;
    this.isFullScreen = false;
  }

  resize(event: ResizeEvent, source: WindowType) {
    this.windowsSizes$.next({[source]: {width: event.rectangle.width}});
  }

  toggleFullscreen(name:WindowType):void{
    if (!this.fullScreenActiveWindow){
      this.enterFullScreen(name);
    } else {
      if (this.fullScreenActiveWindow == name){
        this.exitFullScreen();
      } else {
        this.exitFullScreen();
        this.enterFullScreen(name);
      }
    }
  }

  private triggerResizeEvent(): void{
    // TODO: This "Trigger resize event" is a temporary fix to trigger automatic Mapbox resizing.
    // We should refactor and remove this, and fix Mapbox's resizing ourselves.
    // Perhaps create our own custom event?
    setTimeout(()=>{
      window.dispatchEvent(new Event('resize'));
    });
  }
}

// TODO: Consider moving the enum and Record into a separate file.
export enum WindowType {
  panelMapMain = "panelMapMain",
  panelMapMapbox = "panelMapMapbox",
  panelLayers = "panelLayers",
  panelDashboard = "panelDashboard",
  panelTable = "panelTable",
  panelMainMenu = "panelMainMenu",
  panelLayersLegends = "panelLayersLegends",
}

export interface WindowTypesSizes {
  panelMapMain?: WindowTypesSizesData,
  panelMapMapbox?: WindowTypesSizesData,
  panelLayers?: WindowTypesSizesData,
  panelDashboard?: WindowTypesSizesData,
  panelTable?: WindowTypesSizesData,
  panelMainMenu?: WindowTypesSizesData,
  panelLayersLegends?: WindowTypesSizesData,
}

interface WindowTypesSizesData {
  width: number;
}

/**
 * This is primarily used in two places:
 * a. Inside main-page.component.html, we use these in *ngIf blocks.
 * b. Inside panel-main-menu.component.html, we toggle them true/false.
 * Note: We create this using "Record", which will create an interface from the enum that looks like this:
 *
 *  export interface WindowList{
 *    mapMain: boolean;
 *    map3d:boolean;
 *    panelLayers:boolean;
 *    panelDashboard:boolean;
 *    panelTable: boolean;
 *    panelMainMenu:boolean;
 *  }
 *
 */
export type WindowList = Record<WindowType, boolean>;
