<div style="display: inline-block; margin: 5px;">
    <button class="button-container" nz-button nz-popover [nzPopoverPlacement]="popoverPlacement" [nzPopoverContent]="contentTemplate" [nzPopoverTrigger]="popoverTrigger">
        <i nz-icon nzType="bulb" nzTheme="outline"></i>
    </button>
</div>

<ng-template #contentTemplate>
    <div>
        <p [style.direction]="textDirection" [style.textAlign]="textAlignment" [innerHTML]="popoverContent"></p>
    </div>
</ng-template>