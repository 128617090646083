import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appNumberInputSuffix]'
})
export class NumberInputSuffixDirective implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() numberInputSuffix: string = '';
  @Input() isDisabled: boolean;
  @Output() arrowClick: EventEmitter<any> = new EventEmitter<any>();

  upButtonInstance: any;
  downButtonInstance: any;

  private suffixElement: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    if (!this.isDisabled) {
    this.start();
    }
  }

  /**
   * Called whenever an input property changes (e.g., numberInputSuffix).
   * This is where we update the suffix text if it already exists.
   */
  ngOnChanges(changes: SimpleChanges): void {
    // If numberInputSuffix changed, update or create the suffix
    if (changes['numberInputSuffix'] && !changes['numberInputSuffix'].firstChange) {
      this.updateSuffix();
    }
  }

  ngAfterViewInit() {
    this.el.nativeElement.children[0].style.paddingRight = '1px';
  }

  ngOnDestroy() {
    if (this.upButtonInstance) {
      this.upButtonInstance();
      this.downButtonInstance();
    }
  }

  start() {
    this.el.nativeElement.style.position = 'relative';
    this.el.nativeElement.classList.add('number-input-suffix-container');
    if (this.numberInputSuffix) {
      this.createSuffix();
    }
    this.createHandlers();
  }

  createHandlers() {
    const wrapper: HTMLElement = document.createElement('div');
    wrapper.className = 'ant-input-number-handler-wrap number-input-suffix-wrapper';
    const up: HTMLElement = document.createElement('span');
    up.className = 'ant-input-number-handler ant-input-number-handler-up';
    up.innerHTML = `<i nz-icon nzType="up" nzTheme="outline" style="margin-top: -5px;"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="up" aria-hidden="true"><path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z"></path></svg></i>`;
    this.upButtonInstance = this.renderer.listen(up, 'click', () => this.arrowClick.emit('up'));
    wrapper.appendChild(up);
    const down: HTMLElement = document.createElement('span');
    down.className = 'ant-input-number-handler ant-input-number-handler-down'; // ant-input-number-handler-down-disabled
    down.innerHTML = `<i nz-icon="" nztype="down" nzTheme="outline" style="transform: translateY(-50%);"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="down" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></i>`;
    this.downButtonInstance = this.renderer.listen(down, 'click', () => this.arrowClick.emit('down'));
    wrapper.appendChild(down);
    this.renderer.appendChild(this.el.nativeElement, wrapper);
  }

  // createSuffix() {
  //   const element: HTMLElement = document.createElement('span');
  //   element.innerText = this.numberInputSuffix;
  //   element.className = 'suffix-wrapper'
  //   this.renderer.appendChild(this.el.nativeElement, element);
  // }
  private createSuffix() {
    this.suffixElement = document.createElement('span');
    this.suffixElement.innerText = this.numberInputSuffix;
    this.suffixElement.className = 'suffix-wrapper';
    this.renderer.appendChild(this.el.nativeElement, this.suffixElement);
  }

  /**
   * Called after we detect changes to numberInputSuffix in ngOnChanges.
   * If the suffix element already exists, we update its text.
   * Otherwise, create a new suffix.
   */
  private updateSuffix() {
    if (this.suffixElement) {
      this.suffixElement.innerText = this.numberInputSuffix;
    } else if (this.numberInputSuffix) {
      this.createSuffix();
    }
  }

}
