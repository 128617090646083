import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbar-template-modal',
  templateUrl: './toolbar-template-modal.component.html',
  styleUrls: ['./toolbar-template-modal.component.scss']
})
export class ToolbarTemplateModalComponent implements OnInit {

  constructor() {
  }
  
  ngOnInit(): void {
  }

}
