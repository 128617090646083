<div class="container">
    <label  class="main-title">{{'ONBOARDING.CREATEAWIDEVARIETYOFPLAN' | translate }}</label>
    <label class="sec-title" for="" [innerHTML]="'ONBOARDING.USEOURTAILOTMADEDRAWINGTOOLS' | translate "></label>
    <div class="card-container">
        <div class="card">
            <div class="text-container">
                <div class="card-label">
                    <div style="opacity: 0.5;" class="number-circle">
                        1
                    </div>
                    <label class="text">{{'ONBOARDING.DRAWAPOLYGONONTHEMAP' | translate }}</label>
                </div>
                <div class="card-text-div">
                    <label class="card-text">{{'ONBOARDING.TEXTFORDRAWAPOLYGON' | translate }}</label>
                </div>
            </div>
            <div class="svg-container">
                <img style="scale: 0.7;" src="../../../../assets/main-menu/OnbaordingLeftToolbar.png" alt="">
            </div>
        </div>
        <div class="card">
            <div class="text-container">
                <div class="card-label">
                    <div style="opacity: 0.8;" class="number-circle">
                        2
                    </div>
                    <label class="text">{{'ONBOARDING.DEFINETHEPLANENTITIES' | translate }}</label>
                </div>
                <div class="card-text-div">
                    <label class="card-text">{{'ONBOARDING.TEXTFORDEFINETHEPLAN' | translate }}</label>
                </div>
            </div>
            <div class="svg-container">
                <img style="scale: 0.7;" src="../../../../assets/main-menu/OnbaordingMiddleToolbar.png" alt="">
            </div>
        </div>
        <div class="card">
            <div class="text-container">
                <div class="card-label">
                    <div class="number-circle">
                        3
                    </div>
                    <label class="text">{{'ONBOARDING.SETALLTHEPARAMETERS' | translate }}</label>
                </div>
                <div class="card-text-div">
                    <label class="card-text">{{'ONBOARDING.TEXTETALLTHEPARAMETERS' | translate }}</label>
                </div>
            </div>
            <div class="svg-container">
                <img style="scale: 0.7;" src="../../../../assets/main-menu/OnbaordingRightToolbar.png" alt="">
            </div>
        </div>
    </div>
</div>