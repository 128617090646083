import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, ViewContainerRef
} from '@angular/core';
import {NzMessageService} from "ng-zorro-antd/message";
import {StateService} from "../../../services/state/state.service";
import {CrudService} from "../../../services/crud/crud.service";
import {ENTITY_PLAN_TYPE, URBAN_S3_FOLDERS, URBAN_S3_URL} from "../../../config";
import {WIDGET_OPTIONS} from "../consts";
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {Subscription} from "rxjs";
import {InputModalComponent} from "../../../shared/components/input-modal/input-modal.component";
import Utils from "../../../utils/utils";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {HttpStatusCode} from "@angular/common/http";
import {ADMIN_USERNAME} from "../../permissions/consts";
import { MenuDataStoreService } from 'src/app/services/menuDataStore/menu-data-store.service';

@Component({
  selector: 'app-tab-param-config',
  templateUrl: './tab-param-config.component.html',
  styleUrls: ['./tab-param-config.component.scss']
})
export class TabParamConfigComponent implements OnInit, OnDestroy {

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() saveFinished: EventEmitter<any> = new EventEmitter();
  @Input() projectName: string;
  @Input() paramType: string = 'Default';
  @Input() paramMenuConfigs = [];
  @Input() type;
  @Input() blockConfigMenu: any = {};

  subCategories = {
    landUse: "landuse",
    usage: "usage"
  };

  public isLoadingBlockConfig = false;
  public isLoading = false;
  landUse = {};
  usage = {};
  allLandUses = {};
  allUsage = {};
  isLoadingUploadFile = {};
  entityPlanType = ENTITY_PLAN_TYPE;

  options = WIDGET_OPTIONS;
  searchParam;
  checkChangeInterval;
  orderedMenuData = [];
  filteredMenuData: {} = [];
  private confirmModal: NzModalRef;

  private inputModalComponent:  ComponentFactory<InputModalComponent>;
  private inputModalComponentRef: ComponentRef<InputModalComponent>;

  constructor(
    public dialog: NzModalService,
    private crudService: CrudService,
    private stateService: StateService,
    private menuDataStoreService: MenuDataStoreService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private nzMessageService: NzMessageService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef) {
  }

  async ngOnInit() {
    await this.init();
    this.checkChangeInterval = setInterval(() => {
      this.onChangeConfig();
    }, 100);
    this.inputModalComponent = this.componentFactoryResolver.resolveComponentFactory(InputModalComponent);
    // await this.initAllExcels()
  }

  ngOnDestroy() {
    clearInterval(this.checkChangeInterval);
  }

  openDialog(title, placeholder, params = {}, defaultValue = null) {
    this.inputModalComponentRef = this.viewContainerRef.createComponent(this.inputModalComponent);
    this.inputModalComponentRef.instance.title = title;
    this.inputModalComponentRef.instance.objData = {title, placeholder, params, defaultValue};

    const sub: Subscription = this.inputModalComponentRef.instance.response.subscribe(async result => {

      if (result) {
        result = result['res']
        // @ts-ignore
        const value = result.value.trim();
        // @ts-ignore
        const unTrimmedValue = result.value
        // @ts-ignore
        const params = result.params
        if (params.menuKey === 'landuse') {
          if (this.landUse[unTrimmedValue] || this.landUse[value]) {
            this.nzMessageService.error(`${unTrimmedValue} is already exists`);
            this.inputModalComponentRef.destroy();
            sub.unsubscribe();
            return;
          }
          this.landUse[value] = {displayName: '', color: '#ffff'};
          this.landUse = Utils.clone(this.landUse);
          this.inputModalComponentRef.destroy();
          sub.unsubscribe();
          return;
        }
        if(params.menuKey === 'usage'){
          if (this.usage[unTrimmedValue] || this.usage[value]) {
            this.nzMessageService.error(`${unTrimmedValue} is already exists`);
            this.inputModalComponentRef.destroy();
            sub.unsubscribe();
            return;
          }
          this.usage[value] = {displayName: '', color: '#ffff'};
          this.usage = Utils.clone(this.usage);
          this.inputModalComponentRef.destroy();
          sub.unsubscribe();
          return;

        }
        if (params.type === 'rename' && unTrimmedValue) {
          const bck = Utils.clone(this.blockConfigMenu.menuData[this.type][params.menuKey]);
          delete this.blockConfigMenu.menuData[this.type][params.menuKey];
          this.blockConfigMenu.menuData[this.type][value] = bck;
        }
        if (params.type === 'category') {
          if (this.blockConfigMenu.menuData[this.type][unTrimmedValue] || this.blockConfigMenu.menuData[this.type][value]) {
            this.nzMessageService.error(`${unTrimmedValue} is already exists`);
            this.inputModalComponentRef.destroy();
            sub.unsubscribe();
            return;
          }
          this.blockConfigMenu.menuData[this.type][value] = [];
        } else {
          if (this.blockConfigMenu.menuData[this.type][params.menuKey].map(p => p.code).indexOf(unTrimmedValue) > -1) {
            this.nzMessageService.error(`${unTrimmedValue} is already exists`);
            this.inputModalComponentRef.destroy();
            sub.unsubscribe();
            return;
          }
          this.blockConfigMenu.menuData[this.type][params.menuKey].push({code: unTrimmedValue, widget: 'SLIDER'});
        }
        this.blockConfigMenu.menuData[this.type] = Utils.clone(this.blockConfigMenu.menuData[this.type]);
        this.updateFilteredCategoryMenu()
      }
      this.inputModalComponentRef.destroy();
      sub.unsubscribe();
    });

  }

  getLandUses() {
    return Object.keys(this.landUse);
  }

  onChangeLandUses(e, menuKey, i) {
    this.blockConfigMenu.menuData[this.type][menuKey][i].landUses = e.value;
    this.orderMenuData();
  }

  openOrderParamDialog(data, isLandUse = false) {
  }

  onParamConfigChange(eventValue) {
    if (eventValue === 'add') {
      this.openNewParamDialog();
      return;
    }
    this.openYesNoDialog(eventValue);
  }

  openYesNoDialog(paramConfigProfileName) {
    this.confirmModal = this.modal.confirm({
      nzTitle: `Are you sure?`,
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnCancel: () => {},
      nzOnOk: () => this.changeParamConfig(paramConfigProfileName)
    });
  }

  openNewParamDialog() {

    this.inputModalComponentRef = this.viewContainerRef.createComponent(this.inputModalComponent);
    const sub: Subscription = this.inputModalComponentRef.instance.response.subscribe(async( response : any) => {

      if (response) {
        const value = response.value
        const res = await this.crudService.setMenuConfig(value);
        if (res.status === HttpStatusCode.Conflict) {
          this.nzMessageService.error(`Param Config name already exists`);
          this.inputModalComponentRef.destroy();
          sub.unsubscribe();
          return;
        }
        this.changeParamConfig(value)
      }
      this.inputModalComponentRef.destroy();
      sub.unsubscribe();
      this.paramMenuConfigs = await this.crudService.getAllMenuConfig();
    });
  }

  changeParamConfig(paramConfigProfileName) {
    this.paramType = paramConfigProfileName;
    this.init()
  }

  init(blockConfig = null) {
    try {
      this.isLoading = true;
      if (blockConfig) {
        this.blockConfigMenu = blockConfig;
      }
      const keys = Object.keys(this.blockConfigMenu.menuData[this.type])
      const index = keys.indexOf('landUse');
      if (index !== -1) {
        keys.splice(index, 1);
      }
      this.filteredMenuData = keys.reduce((prev, cur) => {
        prev[cur] = this.blockConfigMenu.menuData[this.type][cur]
        return prev;
      }, {});
      this.landUse = this.blockConfigMenu.menuData[this.type].landUse;
      this.usage = this.blockConfigMenu.menuData[this.type].usage? this.blockConfigMenu.menuData[this.type].usage : {};

      
      Object.keys(this.blockConfigMenu.menuData).forEach(k => {
        this.allLandUses[k] = Utils.clone(this.blockConfigMenu.menuData[k].landUse);
      });


      this.orderMenuData();
    } catch (e) {
      console.error(e);
      this.nzMessageService.error(`Error on fetch menu`);
    }
    this.isLoading = false;
  }

  orderMenuData() {
  }


  // openNewParamDialog(project, index) {
  //
  //   this.inputModalComponentRef = this.viewContainerRef.createComponent(this.inputModalComponent);
  //   const sub: Subscription = this.inputModalComponentRef.instance.response.subscribe(async response => {
  //
  //     if (response) {
  //       // @ts-ignore
  //       const value = response.value
  //       const res = await this.crudService.setMenuConfig(value);
  //       if (res.status === HttpStatusCode.Conflict) {
  //         this.nzMessageService.error(`Param Config name already exists`);
  //         this.listOfDisplayData[index].paramConfigProfileName = project.paramConfigProfileName;
  //         return;
  //       }
  //       if (value.trim() === 'add') {
  //         this.setProjectMenuConfig(project.name, 'Default');
  //         this.listOfDisplayData[index].paramConfigProfileName = 'Default';
  //         return;
  //       }
  //       this.setProjectMenuConfig(project.name, value);
  //       this.paramMenuConfigs.push(value);
  //       this.listOfDisplayData[index].paramConfigProfileName = value;
  //     }
  //     this.inputModalComponentRef.destroy();
  //     sub.unsubscribe();
  //   });
  // }

  async save(showIsLoading = true) {
    this.isLoading = showIsLoading;
    try {
      this.blockConfigMenu.menuData[this.type].landUse = this.landUse;
      // this.blockConfigMenu.order = this.stateService.menuDataOrder;
      await this.crudService.saveParamConfig({value: this.blockConfigMenu});
      this.nzMessageService.success(`All Saved!`);
      const newMenuData = Utils.clone(this.blockConfigMenu.menuData);
      Object.keys(this.blockConfigMenu.menuData).forEach(k => {
        newMenuData[k].landUse = this.allLandUses[k];
      });
      this.menuDataStoreService.nextMenuData(newMenuData);
    } catch (e) {
      this.nzMessageService.error(`Error on save menu`);
    }
    delete this.blockConfigMenu.menuData[this.type].landUse;
    delete this.blockConfigMenu.order;
    const b = Utils.clone(this.blockConfigMenu.menuData);
    b[this.type].landUse = this.landUse;
    this.saveFinished.emit(b);
    this.isLoading = false;
  }

  deleteParam(data, index = null, p = null) {
    this.dialog.confirm({
      nzTitle: `Are you sure you wish to delete ${p || data.menuKey}?`,
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnCancel: () => {},
      nzOnOk: () => {
        if (data.subCategory === 'landuse') { // for landuse
          delete this.landUse[data.menuKey];
          this.landUse = Utils.clone(this.landUse);
          return;
        }
        if(data.subCategory === 'usage'){ // for usage
          delete this.usage[data.menuKey];
          this.usage = Utils.clone(this.usage);
          return;
        }

        if (index > -1) {
          console.log(this.blockConfigMenu.menuData[this.type][data.menuKey]);
          // this.blockConfigMenu.menuData[this.type][menuKey].splice(index, 1);
        } else {
          console.log(this.blockConfigMenu.menuData[this.type][data.menuKey]);
          // delete this.blockConfigMenu.menuData[this.type][menuKey];
          // this.blockConfigMenu.menuData[this.type] = Utils.clone(this.blockConfigMenu.menuData[this.type]);
        }
        this.updateFilteredCategoryMenu()
      }
    });
  }

  updateFilteredCategoryMenu(){
    const landuseKey = ['landUse']
    // const keys = Object.keys(this.blockConfigMenu.menuData[this.type])
    // const index = keys.indexOf('landUse');
    // if (index !== -1) {
    //   keys.splice(index, 1);
    // }
    this.filteredMenuData = landuseKey.reduce((prev, cur) => {
      prev[cur] = this.blockConfigMenu.menuData[this.type][cur]
      return prev;
    }, {});


    const usageKey = ['usage'] 
    // const keys2 = Object.keys(this.blockConfigMenu.menuData[this.type])
    // const index2 = keys2.indexOf('usage');
    // if (index2 !== -1) {
    //   keys2.splice(index2, 1);
    // }
    this.filteredMenuData = usageKey.reduce((prev, cur) => {
      prev[cur] = this.blockConfigMenu.menuData[this.type][cur]
      return prev;
    }, {});
  }

 

  uploadFileStaging(data) {
    let filesList = data.filesList.files
    this.uploadFileToServer(filesList, data.l,data.subCategory,data.funcRef);
  }

  

  async uploadFileToServer(filesList, l,subCategory,funcRef) {
    console.log(filesList, l,subCategory)
    try {
      if(subCategory === 'landuse'){
        this.landUse[l].pattern = (await this.crudService.uploadFile(filesList[0], URBAN_S3_FOLDERS.PATTERNS)).fileName;
        this.landUse = Utils.clone(this.landUse);

        await this.save(false);
      }
      if(subCategory === 'usage'){
        this.usage[l].pattern = (await this.crudService.uploadFile(filesList[0], URBAN_S3_FOLDERS.PATTERNS)).fileName;
        this.usage = Utils.clone(this.usage);

        await this.save(false);
      }
      
    } catch (e) {
      console.error(e);
    }
    this.swithIsLoading({funcRef: funcRef, l: l});
  }

  swithIsLoading(data) {
    // You can now call the function from the child component in the parent component
    data.funcRef.func(data.l);
  }
  

  // getPatternUrl(landUse) {
  //   return `${URBAN_S3_URL}/${URBAN_S3_FOLDERS.PATTERNS}/${this.landUse[landUse].pattern}`;
  // }

  // is3D(landUse) {
  //   return this.landUse[landUse] && this.landUse[landUse].pattern && this.landUse[landUse].pattern.indexOf('.gltf') > -1;
  // }

  getFilteredParam(obj, isReset=false) {
    if (!this.searchParam || isReset) {
      this.filteredMenuData = obj;
      this.searchParam = '';
      return this.filteredMenuData;
    }
    let filtered = {};
    Object.keys(obj).forEach(k => {
      obj[k].forEach(i => {
        try {
          if (i.code && i.code.indexOf(this.searchParam) > -1 || i.inputParameter && i.inputParameter.indexOf(this.searchParam) > -1) {
            if (!filtered[k]) {
              filtered[k] = [];
            }
            filtered[k].push(i);
          }
        } catch (e) {
          console.error(k);
        }

      });
    });
    this.filteredMenuData = filtered;
    return filtered;
  }

  onChangeConfig() {
    try {
      const b = Utils.clone(this.blockConfigMenu.menuData);
      b[this.type].landUse = this.landUse;
      this.onChange.emit(b);
    } catch (e) {

    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.orderedMenuData, event.previousIndex, event.currentIndex);
    this.setOrderMenuData();
  }

  setOrderMenuData() {
  }

  getObjKeys(landUse: {}) {
    return Object.keys(landUse);

  }

  getParamsValues(param: any) {
    return param.value;

  }


  // getHexColor(color) {
  //   return Utils.getHexColor(color);
  // }

}
